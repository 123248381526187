.add_driver_link {
  display: block;
  text-align: right;
}

.submit_product button {
  color: #fff !important;
}

.align_middle {
  display: flex;
  flex-direction: column;
}

.align_middle label {
  margin-top: 25px;
}

.driver_img_container {
  width: 150px;
  height: 100px;
  margin-left: 27px;
  position: relative;
}
.driver_img_container .close_icon {
  position: absolute;
  right: 72px;
}

.driver_img_container img {
  width: auto;
  height: -webkit-fill-available;
  margin-top: 12px;
  object-fit: fill;
}

.driver_uploader .file_upload {
  height: 82px;
}

.driver_uploader .file_upload svg {
  display: block;
  margin: 21px auto 0;
  width: 29px;
}

.btn_driver_user .btn_cancel {
  background-color: #7d7d7d80 !important;
  border: none !important;
  margin-bottom: 11px;
  margin-right: 10px;
  width: 110px;
  color: #000 !important;
}

.table_action_icons {
  width: 16px;
  margin: 0 4px;
}
