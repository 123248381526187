.content_box label {
  font-size: 14px;
  color: #80808075;
  font-weight: 600;
}

.content_box p {
  background: #80808014;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  line-height: 1.8;
}