@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.add_notification {
  display: block;
  width: 200px;
  margin-left: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bg-grey {
  background-color: #f9fbfd;
}

.form-select:focus {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.App span {
  font-weight: 700;
  font-size: 14px;
  margin-right: 12px;
}

.App button {
  background: #fff;
  border: 1px solid grey;
  font-size: 11px;
  padding: 8px 10px;
  border-radius: 4px;
  font-weight: 500;
}

input.form-control {
  border-radius: 5px !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: gray !important;
  font-weight: 500;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: gray !important;
  font-weight: 500;
}

::placeholder {
  color: gray !important;
  font-weight: 500;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.loader_root {
  background: #0000009c;
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
}

.loader {
  left: 50%;
  top: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.loader #spinner {
  box-sizing: border-box;
  stroke: #ffffff;
  stroke-width: 2px;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite,
    rotate 1.6s linear infinite;
  animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite,
    rotate 1.6s linear infinite;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@-webkit-keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.file_upload {
  width: 88px;
  border-radius: 6px;
  border: 2px dashed #7d7d7d;
  height: 89px;
  cursor: pointer;
  margin-top: 12px;
}

.file_upload svg {
  display: block;
  margin: 28px auto 0;
}

@media only screen and (max-width: 1440px) {
  .logo img {
    width: 220px;
    margin-top: 1.5rem;
  }

  .logo_image img {
    width: 471px;
    margin-top: 1em;
  }
}

@media (min-width: 992px) {
  .modal_add_Category .modal-lg,
  .modal-xl {
    max-width: 552px;
  }
}
