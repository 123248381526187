/* .css-144ebsl {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
} */

.btn_add_Category {
  padding: 0;
  margin-left: auto !important;
  display: block !important;
  background-color: transparent !important;
  border-color: transparent !important;
  margin-right: 15px;
}

.btn_add_Category:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn_add_Category.btn-primary:focus {
  background-color: transparent !important;
}

.btn_add_Category path {
  fill: #fe3b69;
  cursor: pointer;
}

.add_Category_list .MuiListItem-gutters {
  padding-left: 0px;
  padding-right: 0px;
}

.add_Category_list .MuiListItemText-root span {
  padding-left: 10px;
}
.category_box_container {
  padding: 18px 0;
  user-select: none;
  cursor: pointer;
}
.category_icon {
  max-width: 100%;
  margin-top: 0 !important;
  width: 18px !important;
  margin: auto;
  display: block;
}

.category_name {
  font-size: 14px;
  color: grey;
  padding-left: 10px;
}

img.category_arrow {
  width: 14px !important;

  margin: 0 0 0 12px;
}

.category_list_container {
  width: 100%;
  min-height: 40px;
}

.add_Category_list .css-h4y409-MuiList-root {
  padding-bottom: 0px;
}

.modal_add_Category .modal-title {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  font-family: "Poppins";
}

.modal_add_Category .form-control {
  background-color: #f2f2f2 !important;
  border: none;
  padding: 9px 11px 11px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
}

.modal_add_Category .btn_cancel {
  background-color: #7d7d7d !important;
  border: none !important;
}

.modal_add_Category .modal-footer button {
  font-size: 14px;
  font-family: "Poppins";
  width: 7rem;
}

.modal_add_Category .btn_submit {
  background-color: #fe3b69 !important;
  border: none !important;
}

.sub_category {
  width: 40px;
  height: 40px;
  position: relative;
  top: -10px;
  border-radius: 100%;
  display: block;
  margin: auto;
}
.sub_category img {
  margin-top: 0 !important;
  width: 100% !important;
  margin: auto;
  display: block;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.category_name_text {
  flex-grow: 1;
}

.card_box .category_list {
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 72vh;
  padding-right: 5px;
}

.category_list .menu_btn_active {
  fill: #fff;
  text-decoration: none;
  background-color: #e5e4e4 !important;
  color: rgb(255, 255, 255) !important;
  width: 100%;
  padding: 0 9px 0px 6px;
}
.category_list .menu_btn_active .category_name {
  color: #000;
}
.category_list .menu_btn_active .category_box_container {
  padding: 10px 0px;
}

.category_div .card_box {
  flex-basis: 21%;
  background-color: rgb(255 255 255);
  border-radius: 5px;
  padding: 15px 10px 15px;
  margin-top: 30px;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px !important;
}

@media only screen and (max-width: 1024px) {
  .category_list_container {
    margin-left: 15px;
    margin-right: 15px;
  }
  img.category_arrow {
    margin: 0 0 0 0;
  }
}
