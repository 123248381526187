    .user_details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 20px;
    }

    .user_details div {
      flex-basis: 20%;
      margin-top: 22px;
    }

    .card_product {
      flex-basis: 50%;
      padding: 22px 0 0;
      position: relative;
      font-family: 'Poppins', sans-serif;
    }

    .card_product .profile_img {
      height: 129px;
      margin-top: 0;
      padding: 4px 10px;
    }

    .shared_badge {
      border: 1px solid #0000002b;
      width: fit-content;
      border-radius: 13px;
      font-size: 8px;
      padding: 0px 8px;
      font-weight: 500;
    }

    .card_product p {
      color: #000;
      font-size: 12px;
    }

    .card_product span {
      color: grey;
      font-size: 11px;
      padding: 0px 7px;
      font-weight: 500;
    }

    .card_product span:nth-child(1) {
      border-right: 1px solid grey;
    }

    .card_product h5 {
      font-size: 11px;
      padding-top: 7px;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
    }

    .shared_date {
      font-weight: 500 !important;
      color: #808080e0 !important;
      font-size: 10px !important;
    }

    .heading_details {
      font-weight: 500;
      padding-top: 25px;
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
    }



    /* .card_order img {
      flex-basis: 20%;
    } */

    /* .card_order div {
      padding-top: 18px;
      width: 100%;
    } */

    .card_order {
      font-family: 'Poppins', sans-serif;
    }

    .card_order h5 {
      font-size: 16px;
      font-weight: 600;
      padding-top: 17px;
    }

    .card_order h5 span {
      position: absolute;
      top: -2px;
      right: 57px;
    }

    .card_order p {
      color: #000;
      margin-bottom: 10px;
    }

    .card_order span:nth-child(1) {
      border-right: 1px solid grey;
    }

    .card_order span {
      color: grey;
      font-size: 11px;
      padding: 0px 7px;
      font-weight: 500;
    }

    .shared_badge img {
      width: 15px;
      height: 12px;
      margin: 3px 4px 2px 2px;
    }

    .box_product {
      height: 315px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .link_order {
      text-align: center !important;
      margin-top: 16px !important;
    }

    .link_order img {
      width: 14px;
    }

    /* .card_order img {
      width: 25px;
    } */

    .order_details {
      padding: 22px 0 60px;
      font-family: 'Poppins', sans-serif;
    }

    .line_h {
      line-height: 2;
    }

    /* 
    .img_container {
      width: 100%;
      height: 100px;
    } */

    .img_container img {
      height: 106px;
      margin-top: 0;
      object-fit: cover;
      object-position: top;
      width: 100%;
    }

    .order_details h5 {
      font-size: 15px;
      padding-top: 17px;
      font-weight: 600;

    }

    .order_details p {
      color: #000;
      font-size: 16px;
    }

    .order_details span {
      color: grey;
      font-size: 14px;
      padding: 0px 7px 0 2px;
      font-weight: 500;
    }


    @media only screen and (min-width:1440px) {}

    @media only screen and (max-width:1023px) {
      .flex-responsive {
        flex-direction: column;
      }

      .card_order h5 {
        padding-top: 4px;
      }
    }

    @media only screen and (max-width:767px) {}