 .close_icon {
   position: absolute;
   right: -8px;
   top: -11px;
   z-index: 9;
 }

 .notification_img_container {
   width: 90px;
   height: 91px;
   margin-left: 27px;
 }

 .form-select {
   font-size: 12px !important;
   color: #000 !important;
   font-weight: 600 !important;
   background-color: #f7f7f7 !important;
   border: 1px solid #ced4da !important;
   border-radius: 5px !important;
   padding: 8px !important;
 }

 .text_description {
   font-size: 12px !important;
   color: #000 !important;
   font-weight: 600 !important;
   background-color: #f7f7f7 !important;
   border: 1px solid #ced4da !important;
   border-radius: 5px !important;
   padding: 8px !important;
   width: 100% !important;
   resize: none !important;
 }

 .submit_product {
   font-size: 14px;
   font-family: "Poppins";
   margin: 15px;
 }

 .submit_product .btn_cancel {
   background-color: #7d7d7d80 !important;
   border: none !important;
   margin-bottom: 11px;
   margin-right: 10px;
   width: 110px;
   color: #000 !important;
   font-size: 14px;
 }

 .submit_product .btn_submit {
   background-color: #fe3b69 !important;
   border: none !important;
   margin-bottom: 11px;
   width: 110px;
   font-size: 14px;
 }

 .label_ft_large {
   font-size: 15px !important;
 }

 svg path {
   fill: #fe3b69;
 }

 .textarea1 {
   resize: vertical;
   margin-left: auto;
   display: block;
   resize: none;
 }

 .ip_width input {
   width: 48%;
 }