.logo img {
  width: 220px;
  margin-top: 4rem;
}

.logo_image img {
  width: 508px;
  margin-top: 2em;
}

.left_side_bar {
  text-align: center;
  margin-top: 9rem;
}

.right_side_content {
  background: #fff;
  max-width: 27rem;
  min-height: 550px;
  margin-top: 4rem;
  -webkit-box-shadow: 0px 9px 18px 0px rgb(0 0 0 / 21%);
  -moz-box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 9px 18px 0px rgb(0 0 0 / 21%);
  padding: 67px 38px;
}

.right_side_content .login_heading {
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  padding-bottom: 19px;
}

.right_side_content label {
  color: #808080ab;
  font-weight: 500;
  font-size: 14px;
}

.right_side_content .form-control {
  background-color: #f2f2f2 !important;
  border: none;
  padding: 9px 11px 11px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
}

.right_side_content .form-control::placeholder {
  color: #808080ab;
  font-size: 13px;
}

.right_side_content .link_forgot_password {
  color: #fd3c6a !important;
  text-align: right;
  display: block;
  font-weight: 600;
  font-size: 13px;
  text-decoration: underline !important;
}

.right_side_content .link_forgot_password button {
  padding: 10px;
  border-radius: 8px;
}

.right_side_content .form-check-label {
  font-size: 14px;
  color: grey;
}
.right_side_content .btn-primary:hover {
  background: #fe3b69 !important;
  border: 1px solid #fe3b69 !important;
}
.right_side_content Button {
  background: #fe3b69;
  border: 1px solid #fe3b69;
  width: 151px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  transition: 1s;
}

.right_side_content .form-check-input {
  width: 19px !important;
  height: 19px !important;
  margin-top: 2px !important;
}

.right_side_content .form-check-input:checked {
  background-color: #fff !important;
  border-color: #74737f !important;
  border-radius: 0px !important;
}

.right_side_content .form-check {
  position: relative !important;
  top: 11px !important;
}

.link_forgot_password svg path {
  fill: #fff;
}

.back_to_login {
  color: #fd3c6a !important;

  display: block;
  font-weight: 600;
  font-size: 13px;
  text-decoration: underline !important;
}

.right_side_content .form-check-input:checked[type="checkbox"] {
  background-image: url(../images/tick.svg) !important;
  background-repeat: no-repeat;
  background-size: auto;
}

.right_side_content .form-check-input[type="checkbox"] {
  border-radius: 0px !important;
}
