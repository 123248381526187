.aplhalist {
  width: 91px;
  background: #fe3b69;
  border: none;
  border-radius: 30px;
}

.aplhalist .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.aplhalist .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  -webkit-transform: none;
  transform: none;
  left: 7px;
  top: 7px;
}

.aplhalist .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px 12px;
}

.aplhalist svg path {
  fill: #fff;
}

.aplhalist .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover {
  border: none;
  outline: none;
}

.aplhalist fieldset {
  border: none !important;
  outline: none !important;
}

.aplhalist .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #ffffff;
}

.aplhalist .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  color: #ffff;
  font-size: 14px;
  font-weight: 700;
}

.aplhalist .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff;
}

.btn_reset_filter button,
.btn_reset_filter .btn-primary:hover {
  background: #fe3b69;
  border: none;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
}

.input-group-text {
  background: #f7f7f7 !important;
  border: none !important;
}

.input-group .form-control {
  border: 1px solid #ced4da !important;
  padding: 8px;
}

.location_on_icon {
  position: absolute;
  right: 8px;
  top: 4px;
}

.form_activity label {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 11px;
}

.form_activity .form-control {
  font-size: 12px;
  color: #000;
  font-weight: 600;
  border-radius: 5px;
}

::placeholder {
  color: grey !important;
  font-weight: normal;
}

.form_activity input {
  background-color: #f7f7f7 !important;
  border: 1px solid #ced4da;
  padding: 8px;
  border-radius: 5px;
}

.age_selection {
  border: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background: #f7f7f7;
  border-radius: 5px;
}

.age_selection button {
  border-radius: 5px !important;
}

.form_activity div button {
  color: #00000078;
  background: #f7f7f7;
  border: none;
  font-size: 12px;
  font-weight: 600;

  padding: 8px 5px;
}

.form_activity div button:hover {
  background: #fe3b69;
}

.form_activity .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary:focus,
.btn-primary:focus {
  background: #fe3b69 !important;
}

.inline_form_field {
  display: flex;
  justify-content: space-between;
}

.inline_form_field label {
  width: 50%;
  margin-top: 11px;
  color: #b3b3b3;
}

.inline_form_field select {
  width: 50%;
  font-size: 11px;
  color: #000000de;
  font-weight: 600;
  background-color: #f7f7f7 !important;
  border: 1px solid #ced4da;
  height: 35px;
}

.css-14pt78w-MuiSlider-rail {
  color: grey;
}

.commission_slider {
  color: #fe3b69 !important;
  margin-top: 11px;
}

.css-1tfve6q-MuiSlider-mark {
  width: 0px !important;
  border-radius: 0 !important;
}

.css-1kz0hui-MuiSlider-valueLabel {
  background-color: transparent !important;
  border-radius: 2px !important;
  color: #fe3b69 !important;
  font-weight: 700 !important;
  top: 0px !important;
}

.card_box .aplhalist select {
  font-size: 13px !important;
  color: #ffffff !important;
  /* background: #fe3b69; */
  font-weight: 600 !important;
  border-radius: 100px !important;
  border: 1px solid #fe3b69 !important;
}

.card_box .aplhalist .form-select {
  background-color: #fe3b69 !important;
  background-image: url("./arrow-down.svg");
}

.form_activity .MuiSlider-valueLabelLabel {
  font-size: 11px;
}

.form_activity .css-yafthl-MuiSlider-markLabel {
  font-size: 11px;
  font-weight: 600;
  color: #808080ad;
  top: 18px;
}

.form_activity .css-1aznpnh-MuiSlider-root {
  padding-top: 5px;
  margin-bottom: 13px;
}

::placeholder {
  color: #00000078;
}

.form-check-input {
  position: absolute;
  height: 1.3em !important;
  margin-top: -0.3rem !important;
}

.form-check-input:checked {
  background-color: #00c821 !important;
  border-color: #00c821 !important;
}

.form-switch .form-check-input {
  width: 2.3em !important;
}

.css-1ejytfe {
  height: 0px !important;
}

span.MuiSlider-rail.css-b04pc9 {
  color: gray !important;
}

span.MuiSlider-valueLabelOpen.MuiSlider-valueLabel.css-1s3sa3n {
  transform: translateY(-100%) scale(1);
  background-color: transparent !important;
  border-radius: 2px !important;
  color: #fe3b69 !important;
  font-weight: 700 !important;
  top: 0px !important;
}

span.MuiSlider-markLabel.css-60f6gz {
  font-size: 11px !important;
  font-weight: 600 !important;
  color: #808080ad;
  top: 18px;
}

.no_of_shares {
  position: relative;

  bottom: 13px;
}

.commission_earned_slider.css-1v5z18m {
  width: auto !important;
  padding-right: 10px;
}