.upload-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

div#contained-modal-title-vcenter {
  font-size: 1rem;
}

.file_upload img {
  height: 100%;
  border-radius: 5px;
}

.prix_container input .form-control:disabled,
.prix_container input .form-control[readonly] {
  background-color: #ffffff !important;
}

.box_prix {
  position: relative;
}

.box_prix .hypen {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 22px;
  z-index: 1;
}

.prix_container span.sign {
  top: 20px;
  font-size: 14px;
  font-weight: 600;
}

.prix_container {
  position: relative;
  border: 1px solid #ced4da;
  height: 48px;
  border-radius: 4px;
  z-index: 9;
  margin-bottom: 14px;
}

.prix_container span {
  position: absolute;
  left: 7px;
  top: 3px;
  font-size: 10px;
  font-weight: 500;
}

.prix_container input {
  height: 45px;
  padding-top: 21px;
  border: none;
  padding-left: 50px;
  font-size: 13px;
  font-weight: 600;
}

.price_filter_container .css-187mznn-MuiSlider-root,
.price_filter_container .css-exkjcx {
  border-radius: 12px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  color: #1976d2;
  -webkit-tap-highlight-color: transparent;
  height: 4px;
  width: 100%;
  padding: 13px 0;
  /* left: 5px; */
}

.info_icon {
  font-size: small !important;
}

.info_icon path,
.add_product_details_icon path {
  fill: #fe3b69 !important;
}

.product_form_table table button img.trash_icon {
  width: 16px;
  margin-top: 15px !important;
  margin-right: 25px;
}

.add_product_details .btn-primary:not(:disabled):not(.disabled).active,
.add_product_details .btn-primary:not(:disabled):not(.disabled):active,
.add_product_details .btn-check:active + .btn-primary,
.add_product_details .btn-check:checked + .btn-primary:focus,
.add_product_details .btn-primary:focus {
  border: none !important;
  background: #fff !important;
  color: #000 !important;
}

.price_filter_container span.price_label:last-child {
  padding-right: 0px;
}

.price_filter_container span.price_label:first-child {
  z-index: 99;
  font-size: 11px;
  padding-right: 30px;
}

.css-eg0mwd-MuiSlider-thumb:hover,
.css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0px 0px 0px !important;
}

.css-7drnjp:hover,
.css-7drnjp.Mui-focusVisible {
  box-shadow: 0px 0px 0px !important;
}

.product_container {
  display: flex !important;
  gap: 20px;
  flex-direction: row;
}

.product_container .filter {
  flex-grow: 1;
  background-color: rgb(255 255 255);
  border-radius: 5px;
  margin-top: 30px;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px !important;
}

.rotate {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.front_image_btn.btn:first-child:hover,
.front_image_btn.btn:first-child:not(.btn-check)
  + .front_image_btn.btn:first-child.btn:hover {
  background: transparent;
  padding: 8px 5px;
  border: 1px solid #80808054;
  color: #000;
  height: 70px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  overflow: hidden;
  font-size: 13px;
  font-weight: 600;
  margin-right: 16px;
  border-radius: 6px;
  margin-bottom: 14px;
  position: relative;
}

.product_add_section svg path {
  fill: #6a6a6a;
}

.add_product_details .btn-primary:focus {
  border: 1px solid #80808054;
  color: #000;
}

.btn_add_product_form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.collapse_filter_menu .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.product_container .product_list {
  flex-grow: 3;
  background: #000;
}

.product_container .arrow_cate_icon {
  position: absolute;
  right: 10px;
  font-size: 25px;
}

.product_container .filter h4 {
  font-size: 1rem;
  font-weight: 600;
}

.rotate_arrow_icon {
  transform: rotate(180deg);
}

.product_container .product_category_filter {
  padding: 5px 17px;
}

.product_container .product_category_filter h6 {
  font-weight: 600;
  font-size: 14px;
}

.product_container .arrow_cate_icon {
  width: 16px !important;
}

.product_container .product_category_filter .category_filter_collapse,
.product_container
  .product_category_filter
  .category_filter_collapse
  .MuiAccordionSummary-expandIconWrapper.css-1fx8m19 {
  min-height: 30px !important;
  /* width: 100% !important; */
}

.product_container
  .product_category_filter
  .css-1betqn-MuiAccordionSummary-content {
  margin: 7px 0 !important;
}

.product_container .product_category_filter .filter_heading,
.product_container .product_category_filter .list_heading {
  font-size: 14px;
  font-weight: 500;
  color: #6a6a6a;
}

.product_container .product_category_filter ul {
  padding-left: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #6a6a6a;
  margin-bottom: 8px;
}

.product_container .product_category_filter ul li {
  list-style: circle;
}

.product_container .product_category_filter ul .category_list_active,
.product_container .product_category_filter ul .category_inner_list_active {
  color: #fe3b69;
  font-size: 14px;
}

.product_container .product_category_filter_container {
  min-height: 100px;
  max-height: 460px;
  overflow-x: hidden;
  overflow-y: auto;
}

.product_container
  .category_main_list
  .css-9xkge5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding-left: 10px !important;
}

.product_container .category_main_list ul.category_inner_list {
  padding-left: 28px !important;
  cursor: pointer;
  user-select: none;
}

.commission_earned_slider .css-1v5z18m {
  width: auto !important;
  padding-right: 10px;
}

.product_search {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.product_search input {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
}

.product_search input::placeholder {
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
}

p.not_found {
  font-size: 10px;
  font-weight: 500;
  user-select: none;
  color: #000000;
  padding-left: 15px;
}

p.sub_cat_not_found {
  font-size: 12px;
  font-weight: 500;
  -webkit-user-select: none;
  user-select: none;
  color: #000000;
  /* text-align: center; */
  padding-top: 6px;
  margin-bottom: 0;
}

.MuiSlider-valueLabel {
  font-size: 12px !important;
}

.add_product_btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.6;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: #fe3b69;
  color: #fff;
}

.product_search_btn {
  height: 35px !important;
  box-shadow: none !important;
  border: 1px solid grey;
}

.product_list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2) !important;
  flex-grow: 1;

  margin-top: 30px;
  padding: 20px;
}

.product_list .product_list_container {
  max-height: 75vh;
  overflow-y: auto;
}

.product_list .product_card_typography {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 12px;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.product_list span.card_product_price {
  font-weight: 600;
  color: #fe3b69;
}

.product_list img {
  object-fit: fill !important;
}

.collapse_filter_menu
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 16px 0 0;
}

.category_not_found {
  list-style: none !important;
  padding-left: 0;
}

.product_category_card_box {
  flex-basis: 21%;
  background-color: rgb(255 255 255);
  border-radius: 5px;
  padding: 19px 14px 0px;
  margin-top: 30px;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px !important;
  min-height: 100%;
  max-height: 100%;
  overflow-y: auto !important;
}

.product_category_card_box .category_name {
  font-size: 15px;
  color: grey;
  padding-left: 10px;
  margin-bottom: 0;
}

.btn_add_Category {
  padding: 0;
  margin-left: auto !important;
  display: block !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn_add_Category:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn_add_Category.btn-primary:focus {
  background-color: transparent !important;
}

.btn_add_Category svg path {
  fill: #fe3b69;
}

.add_Category_list .MuiListItem-gutters {
  padding-left: 0px;
  padding-right: 0px;
}

.add_Category_list .MuiListItemText-root span {
  padding-left: 10px;
}

.category_icon {
  max-width: 100%;
  margin-top: 0 !important;
  width: 18px !important;
  margin: auto;
  display: block;
}

.category_name {
  font-size: 14px;
  color: grey;
}

.category_arrow {
  margin-top: 0 !important;
  width: 14px !important;
  margin-right: 18px;
}

.category_list_container {
  width: 100%;
}

.add_Category_list .css-h4y409-MuiList-root {
  padding-bottom: 0px;
}

.modal_add_Category .modal-title {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  font-family: "Poppins";
}

.modal_add_Category .form-control {
  background-color: #f2f2f2 !important;
  border: none;
  padding: 9px 11px 11px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
}

.modal_add_Category .btn_cancel {
  background-color: #7d7d7d !important;
  border: none !important;
}

.modal_add_Category .modal-footer button {
  font-size: 14px;
  font-family: "Poppins";
  width: 7rem;
}

.modal_add_Category .btn_submit {
  background-color: #fe3b69 !important;
  border: none !important;
}

.file_upload {
  width: 100px;
  border-radius: 6px;
  border: 1px dashed #000;
  height: 89px;
  cursor: pointer;
}

.file_upload svg {
  display: block;
  margin: 25px auto 0;
}

.btn_add_product_form button {
  background: transparent;
  padding: 8px 5px;
  border: 1px solid #80808054;
  color: #000;
  height: 70px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  overflow: hidden;
  font-size: 13px;
  font-weight: 600;
  margin-right: 16px;
  border-radius: 6px;
  margin-bottom: 14px;
  position: relative;
}

.btn_add_product_form button .edit_icon {
  position: absolute;
  right: 0;
  top: 0;
}

.btn_add_product_form button .edit_icon img {
  width: 17px;
  height: 17px;
  opacity: 0.6;
}

.btn_add_product_form img {
  width: 60px;
  height: 50px;
  margin-top: 0;
}

.btn_add_product_form span {
  margin: 0 7px;
}

.btn_add_product_form button:hover {
  background: transparent;
  border: 1px solid #80808054;
  color: #000;
}

.form_2_col {
  width: 50%;
}

.form_activity .label_grey {
  color: #808080a8;
}

.add_product_details label.one {
  font-size: 15px;
}

.add_product_details input,
select {
  height: 36px;
  background-color: #f7f7f7;
  color: #000;
  border: none !important;
}

.form_checkbox_inner {
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
  padding: 16px 18px;
  border-radius: 6px;
}

.form_checkbox {
  border: 1px solid #80808091;
}

.form_checkbox .form-check {
  flex-basis: 132px;
}

.check_btn1 {
  padding: 12px;
  text-align: right;
}

.form-check .form-check-input {
  height: 20px !important;
  width: 20px;
  position: relative !important;
  margin-top: 0 !important;
  background-color: #fff !important;
  border: 1px solid #000 !important;
  border-radius: 0;
  margin-right: 10px;
}

/* .add_product_details .btn-primary:not(:disabled):not(.disabled).active,
.add_product_details .btn-primary:not(:disabled):not(.disabled):active,
.add_product_details .btn-check:active+.btn-primary,
.add_product_details .btn-check:checked+.btn-primary:focus,
.add_product_details .btn-primary:focus {
  background: #fff !important;
  border: 1px solid #80808054 !important;
  color: #000 !important;
} */

.done_check_box_btn {
  display: block;
  margin-left: auto;
  padding: 10px 20px !important;
  border-radius: 6px !important;
  font-size: 12px;
  color: #fff !important;
  margin-top: 10px;
  background-color: #fe3b69 !important;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
}

.arrow_select_btn {
  position: absolute;
  right: 10px;
  top: 8px;
}

.arrow_select_btn svg {
  font-size: 22px;
}

.submit_product {
  font-size: 14px;
  font-family: "Poppins";
  margin: 15px;
}

.submit_product .btn_cancel {
  background-color: #7d7d7d80 !important;
  border: none !important;
  margin-bottom: 11px;
  margin-right: 10px;
  width: 110px;
  color: #000 !important;
  font-size: 14px;
}

.submit_product .btn_submit {
  background-color: #fe3b69 !important;
  border: none !important;
  margin-bottom: 11px;
  width: 110px;
  font-size: 14px;
}

.add_product_details .form_checkbox .form-check-input:checked[type="checkbox"] {
  background-image: url("../images/check.png");
}

.add_product_details select {
  border: 1px solid #ced4da !important;
}

/* .add_product_details .input-group .form-control {
  border: 1px solid #ced4da !important;
  padding: 11px !important;
} */
/* 
.add_product_details .card_box select {
  font-size: 13px;
  color: #000;
  background: #f7f7f7;
  font-weight: 500;
} */

.product_card_box {
  flex-basis: 21%;
  background-color: rgb(255 255 255);
  border-radius: 5px;
  padding: 19px 14px 0px;
  margin-top: 30px;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px !important;
  height: 100vh;
}

.upload_product_heading {
  font-size: 13px;
  font-weight: 400;
  /* opacity: 0.8; */
  display: block;

  position: relative;
  top: 20px;
}

.add_product_aside_content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 8px 24px hsl(210deg 8% 62% / 20%) !important;
  padding: 20px;
}

.add_product_aside_content h5 {
  font-size: 14px;
  text-align: center;
  font-weight: 700;
}

.add_product_aside_content h6 {
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  margin-top: 90px;
  text-align: center;
}

.add_product_aside_content .file_upload {
  width: 93%;
  border-radius: 6px;
  border: 2px dashed #7d7d7d;
  height: 89px;
  cursor: pointer;
  margin: 34px auto;
  display: block;
}

.add_product_aside_content button {
  background-color: #fe3b69 !important;
  border: none !important;
  margin-bottom: 11px;
  width: 93%;
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  padding: 9px 10px;
  display: block;
}

.add_product_aside_content button img {
  width: 20px;
  margin-right: 10px;
  margin-top: -4px;
}

.product_form_table table tr th {
  font-size: 12px;
}

.product_form_table table tr td:nth-child(1) {
  font-size: 12px;
  font-weight: 600;
  padding-top: 24px;
}

.product_form_table .table > :not(caption) > * > * {
  padding: 1rem 0.5rem;
}

.product_form_table table button {
  background: transparent;
  border: none;
  padding: 0;
  text-align: center;
  display: block;
  margin: auto;
}

/* .product_form_table table input,
select {
  height: 33px;
} */

.product_form_table table button:hover {
  background: transparent;
  border: none;
}

.product_form_table table button img {
  width: 16px;
  margin-top: 4px;
}

.collapse_filter_menu .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  height: 0;
}

.collapse_filter_menu .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0 0;
}

.collapse_filter_menu .css-15v22id-MuiAccordionDetails-root {
  padding: 0px 16px 0px;
}

/* .collapse_filter_menu .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
  margin-top: 16px;
} */

.collapse_filter_menu .css-o4b71y-MuiAccordionSummary-content {
  margin: 8px 0;
}

.collapse_filter_menu .css-67l5gl::before {
  height: 0px !important;
}

.collapse_filter_menu .css-17o5nyn {
  margin: 6px 0px !important;
}

.collapse_filter_menu .css-17o5nyn.Mui-expanded {
  margin: 4px 0px;
}

.collapse_filter_menu .css-u7qq7e {
  padding: 0px !important;
}

.select_size_text {
  color: #8080808c;
  font-weight: 600;
  padding-top: 8px;
}

.product_container .form-select {
  background-color: #eee !important;
}

.radio_product svg path {
  fill: #fe3b69;
}

.radio_product label {
  margin-bottom: 0;
}

.p-rel {
  position: relative;
}

.delete_icon {
  position: absolute;
  top: 6px;
  right: 4px;
}

.delete_icon svg path {
  fill: #fe3b69;
}

.border_none {
  border: none !important;
  position: relative;
}

.file_upload .close_icon svg {
  margin: 0 !important;
  font-size: 27px;
}

.file_upload .close_icon svg path {
  fill: #fe3b69;
}

.file_upload .close_icon {
  position: absolute;
  right: -8px;
  top: -11px;
  z-index: 9;
}

@media only screen and (max-width: 1440px) {
  .logo img {
    width: 220px;
    margin-top: 1.5rem;
  }

  .logo_image img {
    width: 471px;
    margin-top: 1em;
  }

  .prix_container span.sign {
    top: 18px;
  }

  .prix_container input {
    height: 41px;
    padding-top: 20px;

    padding-left: 22px;
  }

  .prix_container {
    height: 45px;
  }
}

@media (min-width: 992px) {
  .modal_add_Category .modal-lg,
  .modal-xl {
    max-width: 552px;
  }
}
