.total_count_cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.makeStyles-root-34 {
  overflow: auto;
}

.card_box {
  flex-basis: 21%;
  background-color: rgb(255 255 255);
  border-radius: 5px;
  padding: 19px 25px 0px;
  margin-top: 30px;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px !important;
}

.card_box img {
  width: 40px;
  margin-top: 19px;
}

.card_box h3 {
  font-weight: 700;
  font-size: 23px;
  padding-top: 5px;
}

.card_box h6 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.card_box .MuiTypography-h6 {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.card_box .MuiTableCell-head {
  font-size: 11px;
  padding: 10px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.card_box .MuiInputBase-root {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.card_box .MuiTableCell-alignLeft {
  font-size: 11px !important;
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.card_box p {
  font-weight: 500;
  font-size: 13px;
  color: #808080c4;
  font-family: "Poppins", sans-serif;
}

.card_box .css-q8hpuo-MuiFormControl-root {
  font-size: 10px;
  font-weight: 600;
}

.card_box select {
  font-size: 13px;
  /* color: #ffffff; */
  /* background: #fe3b69; */
  font-weight: 600;
  /* border-radius: 100px;
  border: 1px solid #fe3b69; */
}

.card_box .text_product_title {
  color: #fe3b69;
  padding-bottom: 9px;
}

.card_box .MuiTable-root {
  font-family: "Poppins", sans-serif;
}

.card_box .css-1l6rwmg-MuiLinearProgress-root .MuiLinearProgress-bar {
  border-radius: 5px;
  background-color: #fe3b69;
}

.card_box .css-1l6rwmg-MuiLinearProgress-root {
  height: 4px;
}

.card_box .css-i9gxme {
  margin-top: 10px;
}

.card_box .shared_count {
  text-align: center;
}

.card_box .shared_count h3 {
  font-size: 26px;
  margin-top: 53px;
}

.card_box .shared_count p {
  color: #000;
  font-weight: 600;
}

.card_box .social_image {
  justify-content: center;
}

.card_box .social_image img {
  width: 74px;
}

.card_box .social_image div {
  flex-basis: 23%;
}

.card_box .social_image p {
  margin-bottom: 5px;
}

.card_box .shared_count .share {
  color: #0000004f;
  margin-bottom: 18px;
}

.card_box .MTableToolbar-root-21 {
  padding-right: 0;
}

.card_box .MuiToolbar-gutters {
  padding-left: 0;
}

.card_box .MuiIconButton-label img {
  width: 13px;
  margin-top: 0px;
  margin-right: 4px;
}

.card_box a {
  display: block;
  margin-right: auto;
  width: 100%;
  text-align: right;
  color: #fe3b69 !important;
  font-weight: 600;
  padding-bottom: 11px;
}

.card_box .table_dashboard table tfoot {
  display: none !important;
}

.card_box .MuiPaper-elevation2 {
  box-shadow: none;
  width: 100%;
}

.card_box.recent_sale_product .MuiPaper-elevation2 {
  box-shadow: none;
  width: auto !important;
}

.card_box.h_3 {
  height: 400px;
}

.card_box .makeStyles-root-64 {
  overflow: auto !important;
}

.card_box
  span.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate.css-t752vm {
  border-radius: 5px;
  background-color: #fe3b69;
}

.card_box .css-17ab91p {
  height: 4px !important;
}
