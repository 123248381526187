.menu_icons {
  width: 40%;
}

.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #fff !important;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px !important;
}

img.sidebar_logo_icon {
  width: 62%;
  margin-top: 24px;
  margin: 24px auto 15px;
  display: block;
}

button.MuiButtonBase-root.MuiIconButton-root {
  padding: 0;
}

.menu_btn_active {
  fill: #fff;
  text-decoration: none;
  background-color: #fe3b69 !important;
  color: rgb(255, 255, 255) !important;
}

/* .menu_btn_active svg {
  fill: #fff !important;
}
.MuiListItemIcon-root {
  color: #6a6a6a !important;
} */
.menu_btn_active .menu_icon .change_active {
  fill: #fff;
}

.menu_icon.MuiListItemIcon-root {
  min-width: 47px;
}

.menu_icon svg {
  width: 18px;
}

svg path {
  fill: #6a6a6a;
}

/* .menu_btn_activesvg path:active{
  fill: #ff0303;
} */
.MuiListItemText-root span {
  font-size: 14px;
  font-weight: 600;
  color: grey;
  font-family: "MONTSERRAT";
}

.menu_btn_active span {
  color: #fff;
}

.user_detail_toolbar {
  color: #000;

  margin-left: auto;
}

.user_detail_toolbar button {
  background: transparent;
  border: none;
}

.user_detail_toolbar .dropdown-toggle::after {
  display: none !important;
}

.user_detail_toolbar button.btn-primary:hover {
  background: transparent;
  border: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary:focus,
.btn-primary:focus {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
}

.user_detail_toolbar .dropdown-menu {
  min-width: 100%;
  padding: 0.5rem 13px;
  font-size: 13px;
  font-weight: 700;
}

.user_detail_toolbar .dropdown-menu svg {
  font-size: 15px;
  margin-right: 6px;
}

.user_detail_toolbar .dropdown-item:active {
  background-color: transparent;
}

.dropdown-item {
  padding: 0 20px;
  font-size: 14px;
}

.user_detail_toolbar h5 {
  font-size: 13px;
  margin-bottom: 1px;
  margin-top: 12px;
  color: #000;
}

.user_detail_toolbar p {
  font-size: 11px;
  color: #000;
}

.user_detail_toolbar img {
  width: 62px;
}

.MuiToolbar-root {
  justify-content: space-between;
}

.d-flex-toolbar {
  display: flex !important;
  justify-content: flex-end;
  width: 100%;
}

.user_detail_toolbar.dropdown #dropdown-basic:active,
.user_detail_toolbar.dropdown #dropdown-basic:focus {
  background-color: #fff !important;
}
