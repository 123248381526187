.transaction_sort {
  position: absolute;
  z-index: 1;
  padding: 20px 30px;
}
.transaction_span {
  margin-top: 5px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 12px;
  margin-left: 12px;
}
