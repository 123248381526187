.size_box {
  background: #fe3b69;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 16px;
  padding: 7px 8px 7px 13px;
  color: #fff;
  font-size: 13px;
  margin-right: 7px;
  margin-bottom: 10px;
}
.form_add_product select {
    
    height: 35px;
}

.size_box img {
  width: 18px;
  margin-top: 0;
  margin-left: 7px;
}

.size_box span {
  font-weight: 600;
}

.form_add_product select {
  border: 1px solid #ced4da !important;
  color: #000;
  font-size: 12px;
  font-weight: 500;
}

.form_add_product label {
  font-size: 13px;
}

.brand_select_box {

  display: flex;
  gap: 22px;
  flex-wrap: wrap;

  justify-content: flex-start;
  margin-bottom: 10px;
}

.brand_btn {
  display: flex;
  background: #fe3b69;
  width: fit-content;
  justify-content: space-between;
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}

.brand_grey_btn {
  background: grey;
}

.brand_btn p {
  color: #fff;
  padding-right: 20px;
  margin-bottom: 0;
  font-size: 14px;
}

.brand_btn svg {
  font-size: 18px;
}

.brand_btn svg path {
  fill: #fff;
}

.brand_select_box .css-j204z7-MuiFormControlLabel-root {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.brand_select_box div {
  width: fit-content;
}

.brand_select_box .css-ahj2mt-MuiTypography-root {
  font-size: 13px;
  padding-top: 12px;
  font-weight: 600;
}

.brand_select_box .css-j204z7-MuiFormControlLabel-root .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 !important;
  margin-left: 12px !important;
  margin-top: 9px !important;
}

.brand_select_box .css-i4bv87-MuiSvgIcon-root {
  width: 0.8em;
}

.add_brand_box {
  box-shadow: rgb(149 157 165 / 52%) 0 0px 4px;
  width: 238px;
  padding: 16px;
  border-radius: 7px;
}

.add_brand_box .input-group .form-control {
  background: #e8e9eb;
  height: 32px;
  font-size: 12px;
}

.brand_list {
  display: flex;
  justify-content: space-between;
}

.brand_list h5 {
  font-size: 15px;
  color: #bdc0c5;
  margin-bottom: 0;
}

.brand_list svg {
  font-size: 17px;
}

.brand_list svg path {
  fill: #fe3b69;
}

.add_brand_box hr {
  margin: 10px 0;
}

.brand_select_box .form-check-input {
  position: relative;
  height: 0.9rem !important;
  width: 0.9rem !important;
}

.brand_select_box .form-check-input[type=checkbox] {
  border-radius: 0px !important;
}

.brand_select_box .brand_check_box .form-check-input:checked {

  height: 0.9rem !important;
  width: 0.9rem;
  position: relative !important;
  margin-top: 3px !important;
  background-color: #fff !important;
  border: 1px solid #000 !important;
  border-radius: 0;
  margin-right: 8px;

}

.brand_select_box .brand_check_box .form-check-input {

  height: 0.9rem !important;
  width: 0.9rem;
  position: relative !important;
  margin-top: 3px !important;
  background-color: #fff !important;
  border: 1px solid #000 !important;
  border-radius: 0;
  margin-right: 8px;

}

.brand_select_box .brand_check_box .form-check-label {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 12px;
}

.brand_select_box .form-check-input:checked[type="checkbox"] {
  background-image: url("../images/check.png") !important;
}

.edit_table {
  font-size: 18px !important;
  margin-left: 20px;
  margin-right: 5px;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.add_brand_box h6 {
  font-size: 13px;
}