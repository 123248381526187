.commission_datatable_paycheck .form-check-input:checked[type=checkbox] {
  background-image: url("../images/check.png");
}

.btn_pay {
  position: relative;
  top: 57px;
  z-index: 1;
  left: 14px;
  background-color: #fe3b69 !important;
  border-color: #fe3b69 !important;
}

.commission_table .MuiTypography-h6 {   
    padding-left: 5rem;
}